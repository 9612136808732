;(function() {
"use strict";

'use strict';
/*jshint -W109 */ //Use double quotes in string
angular.module('mainApp')
  .constant('RuntimeConfiguration',
    // The content below will be replaced by the build script according to the
    // json defined in dbw-config.<NODE_ENV>.json
    //START_CONFIG_BLOCK
{"environment":"PROD","logging":{"sendToServer":{"debug":false,"info":false,"warn":false,"error":true},"writeToConsole":{"debug":false,"info":false,"warn":false,"error":true}},"analytics":{"debug":false,"tealium":{"profile":"lobby-dk-spa","env":"prod"}},"http":{"timeout":20000},"session":{"idle":600,"timeout":300},"loginMethods":{"FI":{"household":[],"corporate":[]},"SE":{"household":[],"corporate":[]},"DK":{"household":["login-method-nemid"],"corporate":[]},"NO":{"household":[],"corporate":[]}},"signingMethods":{"FI":{"household":[],"corporate":[]},"SE":{"household":[],"corporate":[]},"DK":{"household":["signing-method-nemid"],"corporate":[]},"NO":{"household":[],"corporate":[]}},"enabledFeatures":["nccl.contactmenu","overdraft.contactmenu","DK.nasa.login","nasa.redirect"],"disabledFeatures":["DK.legacy.login","DK.agreement.login"],"pexServerURL":"meet.nordea.com","nasa":{"environment":"https://identify.nordea.com","scope":"ndf openid agreement.optional personidentifier offline_access","signingScope":"ndf openid agreement","clientId":{"NDHWDK":"vFTGkhc42ngocVF0qycQ"},"forceNasaSigning":true}}
    //END_CONFIG_BLOCK
);
}());
