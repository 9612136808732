;(function() {
"use strict";

'use strict';

/**
 * This handler does the following checks before alloving navigation to a state:
 * check that authentication exists - unless it is a public state
 * check that the session has the needed autorisation
 */
angular.module('mainApp')
    .run(function ($log, $rootScope, $state, SessionService, AuthorisationService) {
        $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams, options) {
            if ((toState.name === 'login' || toState.name === 'select-agreement') && SessionService.isAuthenticated()) {
                SessionService.logout();
            } else if (toState.name === 'select-agreement' && !SessionService.isAuthenticated()) {
                event.preventDefault();
                $state.go('login').catch(angular.noop);
            } else if (toState.public && !toState.redirectTo) {
                // If state is public (and isn't a redirect state), we break here and skip authorization
            } else if (!SessionService.isAuthenticated()) {
                $log.debug('Session not valid, redirecting to login. State pushed for later ' + toState.name);
                event.preventDefault();
                SessionService.pushState(toState, toParams).catch(angular.noop);
            } else if (!AuthorisationService.isAuthorisedToState(toState)) {
                $log.debug('Not authorized to ' + toState.name);
                event.preventDefault();
                $state.go('common.noaccess').catch(angular.noop);
            } else if (toState.redirectTo) {
                /**
                 * Allows you to use 'redirectTo: <state id>' in angular ui router state configurations.
                 *
                 * Note for the future: API listening to state changes is different in angular-ui-router 1.x.
                 */
                event.preventDefault();
                $state.go(toState.redirectTo, toParams, options).catch(angular.noop);
            }
        });
        $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
            $log.debug('State change error ' + toState.name + JSON.stringify(error));
            //SessionService.logout('error');
        });
    });
}());
