;(function() {
"use strict";

'use strict';

angular.module('mainApp')
    .run(function (ndDateValidatorFactory, TimeService) {
        ndDateValidatorFactory.setCurrentTimeGetter(TimeService.currentTime);
    });
}());
