;(function() {
"use strict";

angular.module('mainApp')
    .config(configureRouting)
    .run(setupStateChangeEvents)
    .value('fullscreenReturnState', {});

function getNcclRoute(subjectToken) {
    var route = 'https://netbank.nd.test.nordea.dk';

    switch (window.location.host) {
        case 'lobby-rel01.nd.test.nordea.dk':
            route = 'https://netbank-rel01.nd.test.nordea.dk'
            break;
        case 'nccl.preprod.nordea.dk':
            route = 'https://netbank.preprod.nordea.dk'
            break;
        case 'flight-nccl.nordea.dk':
            route = 'https://flight-netbank.nordea.dk';
            break;
        case 'nccl.nordea.dk':
            route = 'https://netbank.nordea.dk';
            break;
        default:
            break;
    }

    return route + '/nccl-origination-dk?subject_token=' + subjectToken;
}

function configureRouting($stateProvider, $urlRouterProvider) {

    // Defines default state
    // Resolves "Error: $rootScope:infdig Infinite $digest Loop" (this may be remnant of AngularAMD dependency in the prototype app)
    $urlRouterProvider.otherwise(function ($injector) {
        var $state = $injector.get("$state");
        $state.go('login');
    });

    $stateProvider
        .state('login', {
            url: '/?state&test',
            template: '<login-page></login-page>',
            tracking: false,
            public: true
        })
        .state('select-agreement', {
            url: '/select-agreement'
        })
        .state('logout', {
            template: '',
            controller: function(SessionService) {
                SessionService.logout('logout');
            },
            tracking: true,
            public: true
        })
        .state('start', {
            template: '<div ui-view class="fluid-container"></div>'
        })

        .state('common', {
            templateUrl: 'main/main.tpl.html',
            data: {features: ['common']}
        })
        .state('common.noaccess', {
            template: '<noaccess-page></noaccess-page>',
            public: true
        })
        .state('common.notfound', {
            template: '<notfound-page></notfound-page>',
            public: true
        })
        .state('household', {
            url: '',
            redirectTo: localStorage.getItem('product') === 'overdraft' ? 'overdraft-origination.main' : 'nccl-origination.main',
            params: {
                // default to privatlån because this is used only on development environment
                // real users are redirected to the flow byt POSTing a request to /nccl_store (see NcclStoreController.java)
                productType: localStorage.getItem('product') !== null ? localStorage.getItem('product') : "private"
            },
            template: '<ui-view/>',
            tracking: false
        });
}

function setupStateChangeEvents($rootScope, fullscreenReturnState, SessionService, $http) {
    /*
     * Catching redirect to nccl origination and force customer to land in new nccl
     */
    $rootScope.$on('$stateChangeStart', function (event, toState) {    
        if (_.startsWith(toState.name, 'nccl-origination')) { 
            event.preventDefault();

            $http({
                method: 'GET',
                url: 'api/dbf/ca/token-exchange-v1/subject/token?target_client_id=NDHWDK',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + SessionService.token()
                }
            })
            .then(function(response) {
                window.location.href = getNcclRoute(response.data.subject_token);
            });

        }
    });

    /**
     * Used for navigating back from a fullscreen state
     */
    $rootScope.$on('$stateChangeSuccess', function (evt, to, params, from) {
        if (_.startsWith(to.name, 'fullscreen') && !_.startsWith(from.name, 'fullscreen')) {
            fullscreenReturnState.name = (from.name === '' || from.name === 'login') ? SessionService.style() : from.name;
        }
    });

}
}());
