;(function() {
"use strict";

'use strict';

angular.module('mainApp')
    .directive('input', inputAutocompleteOff);

function inputAutocompleteOff() {
    return {
        restrict: 'E',
        compile: function(tElem, tAttrs) {
            if (!tAttrs.autocomplete) {
                tAttrs.$set('autocomplete', 'off');
            }
        }
    }
}
}());
