;(function() {
"use strict";

'use strict';
/**
 * Notice:
 * This way of mapping urls to DBF services is deprecated. Instead define and use the target urls directly in the modules.
 *
 */
angular.module('mainApp')
    .constant('URLConfiguration',
        {
            // 'urls': [
            //     {
            //         "url": "/api/dbf/{COUNTRY}/accounts-v1",
            //         "target": "/api/dbf/{COUNTRY}/accounts-cbp-v2",
            //         "country": "FI"
            //     }
            // ]
        }
    );
}());
