;(function() {
"use strict";

angular.module('mainApp', [
        // Vendor modules
        'ui.router',
        'ngResource',
        'ngAnimate',
        'ngStorage',
        'ngAria',
        'angular-velocity',
        // Add your module names here!
        'material.components.gridList',
        'dbw-core',
        'dbw-localization',
        'dbw-common',
        'dbw-login',
        'dbw-authsign.api',
        'dbw-labels',
        'dbw-lobby-common.error',
        'dbw-lobby-common.tracker',
        'dbw-nccl-origination-dk',
        'dbw-overdraft-origination-dk'
    ]);
}());
