;(function() {
"use strict";

angular.module('mainApp')
    .config(configureProvider);

/* @ngInject */
function configureProvider($provide) {
    $provide.decorator('AuthsignAPIConfig', function($delegate, Configuration, SessionService, featureService) {
        var audience = Configuration.tokenAudience;

        if(audience) {
            $delegate.tokenAudience = _.isArray(audience) ? audience.join(',') : audience;
        }

        /*
        $delegate.tokenScope = 'ndf.prospect';
        $delegate.clientId = function() {
            return 'OCS' + SessionService.country();
        };
        */

        return $delegate;
    });
}
}());
