;(function() {
"use strict";

/**
 * Accessibility: When clicking navbar menu item, resets focus so that the next
 * element in Tab cycle is the skip link.
 *
 * This a keyboard user to skip straight to the main content instead of the focus staying
 * at a menu item and forcing the user to cycle through several
 * menu items before getting to the content.
 */

$(document).ready(function () {
    var navbarStateChangers = 'header[role=navigation] [ui-sref]';
    $('body').on('click', navbarStateChangers, resetFocus); // Works on Enter click, too (Chrome, IE, FF)
});

function resetFocus() {
    var target = $('<span>').attr('tabindex', -1).addClass('show-for-sr'); // Prevent target from becoming visible for a short time when we focus on it
    $('body').prepend(target);
    target.focus().blur().remove();
}
}());
